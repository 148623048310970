<nav class="navbar navbarDesktop navbar-expand-lg bg-light bradius mb-4" 
*ngIf="loggedIn && (path === '/oportunidades' || path === '/administracion')">
    <div class="container-fluid" >
        <div class="collapse navbar-collapse" id="navbarText">

            <!-- Mensaje de bienvenida a la izquierda -->
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li *ngIf="loggedIn && userAuth !== null" class="nav-item">
                    <span class="navbar-text text-white">
                        <span style="font-size:1.1rem; margin-right: 0.15rem;">
                            {{ userGender === 'male' ? 'Bienvenido' : (userGender === 'female' ? 'Bienvenida' :
                            'Bienvenid@') }}
                        </span>
                        <span style="margin-right: 10px;"></span>
                        <span style="color:#f09502;font-size:1.1rem;">{{ nameUser }}</span>
                    </span>
                </li>
            </ul>

            <!-- Botón de conectar wallet en el centro (naranja) -->
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                <!-- Mostrar botón si no hay wallet conectada -->
                <li *ngIf="!addressUser && !hideBlockchain" class="nav-item connect">
                    <button class="btn btn-warning" (click)="connect()"
                        style="color: white; background-color: #f09502;">
                        <img src="../../../assets/img/metamask.png" alt="Conectar Wallet"> Conectar Wallet
                    </button>
                </li>

                <!-- Mostrar dirección conectada -->
                <li *ngIf="addressUser && !hideBlockchain" class="nav-item">
                    <a class="nav-link address bradius text-white"
                        [href]="'https://polygonscan.com/address/' + addressUser" target="_blank">
                        Address: {{ addressUserTruncate }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>