import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { KycService } from 'src/app/services/kyc.service';
import Swal from 'sweetalert2';
import { KycService as ModalKycSrv } from 'src/app/services/modal-kyc/kyc.service';
import { DigitalWalletService } from 'src/app/services/digital-wallet.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-menu-movil',
  templateUrl: './menu-movil.component.html',
  styleUrls: ['./menu-movil.component.css']
})
export class MenuMovilComponent implements OnInit {
  userAuth: any = null;
  loggedIn: boolean = false;
  adminFlag: boolean = false;
  kycFlag: boolean = false;
  menuOpened: boolean = false;
  balance: number = 0;
  balanceError: boolean = false;
  isLoadingBalance: boolean = false;
  digitalWalletCollapsed: boolean = false;
  eurosCollapsed: boolean = false;
  helpCollapsed: boolean = false; 
  profileCollapsed: boolean = false;
  myInvestmentsCollapsed: boolean = false;
  hideBlockchain: boolean = true;

  constructor(
    private authSrv: AuthService, 
    private kycSrv: KycService,
    private crf: ChangeDetectorRef,
    private router: Router,
    private modalKycSrv: ModalKycSrv,
    private digitalWalletSrv: DigitalWalletService,
    private afs: AngularFirestore
  ) {}

  logout() {
    Swal.fire({
      icon: 'info',
      title: '¿Quieres salir de la aplicación?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Cancelar',
      cancelButtonText: `Ok`,
    }).then(async (result: any) => {
      if (!result.isConfirmed) {
        await this.authSrv.logout();
        this.router.navigateByUrl('/login');
      }
    });
  }

  ngOnInit(): void {
    this.authSrv.userAuth.subscribe((res: any) => {
      this.userAuth = res;
      this.crf.detectChanges();
    });

    this.authSrv.loggedIn.subscribe((res: any) => {
      this.loggedIn = res;
      this.crf.detectChanges();
    });

    this.authSrv.admin.subscribe((res: any) => {
      this.adminFlag = res;
      this.crf.detectChanges();
    });

    this.kycSrv.kycSuccess.subscribe((res: any) => {
      this.kycFlag = res;
      this.crf.detectChanges();
    });

    this.digitalWalletSrv.currentBalance.subscribe((res: any) => {
      if (res) {
        this.balance = res.balance;
        this.balanceError = res.balanceError;
        this.isLoadingBalance = res.isLoadingBalance;
        this.crf.detectChanges();
      }
    });

    this.authSrv.userAuth.subscribe((res: any) => {
      this.userAuth = res;
      this.afs.collection('users').doc(res?.uid).valueChanges().subscribe((user: any) => { 
          this.hideBlockchain = user?.hideBlockchain ?? true;
          })
      this.crf.detectChanges();
    });
  }

  

  openModalKyc() {
    this.modalKycSrv.openModalKyc();
  }

  checkFunds() {
    this.digitalWalletSrv.checkFunds();
  }

  selectedMenu(collapsed: string){
    switch (collapsed) {
      case 'digitalWalletCollapsed':
        this.digitalWalletCollapsed = !this.digitalWalletCollapsed;
        this.eurosCollapsed = false;
        this.helpCollapsed = false;
        this.profileCollapsed = false;
        this.myInvestmentsCollapsed = false;
        break;
      case 'eurosCollapsed':
        this.eurosCollapsed = !this.eurosCollapsed;
        // this.digitalWalletCollapsed = false;
        this.helpCollapsed = false;
        this.profileCollapsed = false;
        this.myInvestmentsCollapsed = false;
        break;
      case 'helpCollapsed':
        this.helpCollapsed = !this.helpCollapsed;
        this.digitalWalletCollapsed = false;
        this.eurosCollapsed = false;
        this.profileCollapsed = false;
        this.myInvestmentsCollapsed = false;
        break;
      case 'profileCollapsed':
        this.profileCollapsed = !this.profileCollapsed;
        this.digitalWalletCollapsed = false;
        this.eurosCollapsed = false;
        this.helpCollapsed = false;
        this.myInvestmentsCollapsed = false;
        break;
      case 'myInvestmentsCollapsed':
        this.myInvestmentsCollapsed = !this.myInvestmentsCollapsed;
        this.digitalWalletCollapsed = false;
        this.eurosCollapsed = false;
        this.helpCollapsed = false;
        this.profileCollapsed = false;
        break;
      default: 
        break;
    }
  }
}
