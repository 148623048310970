import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { get } from 'jquery';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitalWalletService {

  private balance = new BehaviorSubject<any>({});
  currentBalance = this.balance.asObservable();

  constructor( private fns: AngularFireFunctions) { }


    getBalance(){
      return this.balance;
    }

    getBalanceValue(){
      const balanceData = this.getBalance() as any;
      return balanceData._value;
      //if(value.balance) return value.balance;
    }

    setBalance(balance: any){
      this.balance.next(balance);
    }

    checkFunds() {
      this.setBalance({
        ...this.getBalanceValue(),
        isLoadingBalance: true
      })
      this.fns.httpsCallable('getWalletMangoPay')({}).subscribe({
        next:(res: any) => {
          this.setBalance({
            balanceError: false,
            balance: res.Balance.Amount / 100
            //balance : 0
          })
          this.executeTimeOut();
        },
        error:(error: any) => {
          console.log(error.message);
          this.setBalance({
            balanceError: true,
            balance: 0
          })
          this.executeTimeOut();
        }
      }
      );
    }

    executeTimeOut(){
      const balance = this.getBalanceValue();
      setTimeout(()=>{
        this.setBalance({
          ...balance,
          isLoadingBalance: false
        })
      }, 5000);
    }
}
