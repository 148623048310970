import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import axios from 'axios';
import { ModalConnector } from 'src/app/services/modal-connection.service';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';
import { KycService as KycServiceOriginal } from 'src/app/services/kyc.service';
import { SelectorsService, PhonePrefix } from 'src/app/services/selectors.service';
import Swal from 'sweetalert2';
import { ModalKycComponent } from '../modal-kyc.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

export interface Nationality {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-kyc-register',
  templateUrl: './kyc-register.component.html',
  styleUrls: ['./kyc-register.component.css']
})

export class KycRegisterComponent implements OnInit {

  ////////////GLOBALES////////////
  spinnerDiameter = window.innerWidth <= 480 ? 70 : 100; // Cambia 768 y 50 a los valores que necesites
  duplicatedKycSubscription: any;
  duplicatedKYC: any;


  
  //////////////// PERSON VARIABLES ///////////
  userName: string = '';
  userLastname: string = '';
  userBirthdate: Date = new Date();
  nationality: string = '';
  residence: string = '';
  address: string = '';
  city: string = '';
  mailAddress: string = '';
  phonePrefix: string = '';
  phoneNumber: string = '';
  studyLvl: string = '';
  profession: string = '';
  sourceFunds: string = '';
  mainSourceFunds: string = '';
  sourceFundsOrigin: string = '';
  fundsPercentage: string = '';
  negative_support: string = '';
  target_invest: string = '';
  invest_goal: string = '';
  knowledge_quiebra: string = '';
  isUsaCitizen: string = ''
  isPoliticallyExposed: string = ''
  isProfessionalInvestor: string = ''
  experienceAsInvestor: string = ''
  businessManagement:string = '';
  sup500k: boolean = false;
  oper_job: boolean = false;
  requiredField: any = '';
  countries: Nationality[] = [];
  subscription: any;
  disablebuton: any = '';
  token:string = '';
  configuration:{}= {};
  lastoper: any = '';
  openlink2: any;
  openlink: any;
  stage1Progress: number = 0;
  stage2Progress = 0;
  stage3Progress = 0;
  currentStage = 1;
  currentStep = 1;
  url_neo_video:string ='https://neocheck.net/api/v1/VideoIdentifications/unattended/link';
  kycInfo: any = null;
  typeRegister: any
  isCompany: boolean = false;
  isPerson: boolean = false;
  phonePrefixes: PhonePrefix[]
  files: any = [];
  representants: Map<number,any> = new Map();
  index: number = 1;
  isLoading: boolean = false;
  loadingMessage: string = '';

  /////// COMPANY VARIABLES /////////
  companyName: string = '';
  companyType: string = '';
  cif:string = '';
  companyResidence:string = '';
  companyAddress:string = '';
  companyMailAddress: string = '';
  companyCity: string = '';
  companyIsProfessionalInvestor: string = '';
  companyRepresentTitle: any;
  sup40m: boolean = false;
  companyRepresentName: any;
  companyRepresentLastname: any;
  companyRepresentBirthday: any;
  companyRepresentNationality: any;
  companyRepresentResidence: any;
  companyRepresentAddress: any;
  companyRepresentCity: any;
  companyPhoneNumber: any;
  companyRepresentantisUsaCitizen: any;
  companyRepresentantisPoliticallyExposed: any;
  isRealRepresentant: any;
  realRepresentantsQuantity: any;
  companyRepresentMailAddress: any;
  acta: any;
  fileacta: any;
  escrituras: any;
  filescrito: any;
  otrosdoc: any;
  fileotros: any;
  countriesApproved:string[] = ['Alemania','Austria','Bulgaria','Belgica','Chipre','Croacia','Dinamarca','Eslovaquia','Eslovenia','España','Estonia','Finlandia','Francia','Grecia','Hungría','Irlanda','Italia','Letonia','Lituania','Luxemburgo','Malta','Paises Bajos','Polonia','Portugal','Republica Checa','Rumanía','Suecia'];
  constructor( 
    private zone: NgZone,
    private afs:AngularFirestore,
    private router: Router , 
    public sanitizer: DomSanitizer, 
    public fns: AngularFireFunctions,
    public select:SelectorsService, 
    private modalConnectionService:ModalConnector ,
    private kycSrv: KycService,
    private kycService:KycServiceOriginal,
    public dialogRef: MatDialogRef<ModalKycComponent>,
    private storageService: AngularFireStorage,
    private authSrv: AuthService
  ) {
    this.duplicatedKycSubscription = this.kycService.kycDuplicated.subscribe((data:Boolean | undefined | null) => {
      if(data){
        this.duplicatedKYC = true;
      }
    });
    this.countries = this.select.nacionality;
    this.phonePrefixes = this.select.phonePrefix;
   }

  ngOnInit(): void {
    this.neoToken();
    this.typeRegister = this.kycSrv.currentTypeRegister.subscribe(data => {
      if (data) {   
        /////////////////////// DEFINE TYPE INVERSOR //////////////////
        this.isCompany = data?.inversorType === 'company';
        this.isPerson = data?.inversorType === 'person';

        /////////////////////// COMPANY ATTRIBUTES /////////////////////
        this.companyName = data?.companyName;
        this.cif = data?.cif;
        this.companyType = data?.companyType;
        this.companyAddress = data?.companyAddress;
        this.companyCity = data?.companyCity;
        this.companyResidence = data?.companyResidence;
        this.companyMailAddress = data?.companyMailAddress;
        this.companyIsProfessionalInvestor = data?.companyIsProfessionalInvestor;
        this.companyRepresentAddress = data?.companyRepresentAddress;
        this.sup40m = data?.sup40m;
        this.requiredField = data?.profesionalClient;
        ////////////////////// SHARED ATTRIBUTES ////////////////
        this.oper_job = data?.oper_job;
        this.lastoper = data?.lastoper;
        //////////////////////////// SECOND STAGE COMPANY ////////////////////////
        this.companyRepresentTitle = data?.companyRepresentTitle;
        this.companyRepresentName = data?.companyRepresentName;
        this.companyRepresentLastname = data?.companyRepresentLastname;
        this.companyRepresentResidence = data?.companyRepresentResidence;
        this.companyRepresentMailAddress = data?.companyRepresentMailAddress;
        this.companyRepresentAddress = data?.companyRepresentAddress;
        this.companyRepresentNationality = data?.companyRepresentNationality;
        this.companyRepresentCity = data?.companyRepresentCity;
        this.companyRepresentantisPoliticallyExposed = data?.companyRepresentantisPoliticallyExposed;
        this.companyRepresentantisUsaCitizen = data?.companyRepresentantisUsaCitizen;
        this.isRealRepresentant = data?.isRealRepresentant;

        ////////////////////////// PERSON //////////////////////
        this.userName = data?.nombre;
        this.userLastname = data?.apellido;
        this.address = data?.domicilio;
        this.userBirthdate = data?.fecha_de_nacimiento;
        this.mailAddress = data?.codigo_postal;
        this.nationality = data?.nacionalidad;
        this.residence = data?.pais_de_residencia;
        this.city = data?.ciudad;
        this.isUsaCitizen = data?.vive_en_usa;
        this.isPoliticallyExposed = data?.trabaja_funcion_publica;
        this.isProfessionalInvestor = data?.isProfessionalInvestor;
        this.invest_goal = data?.invest_goal;
        this.target_invest = data?.target_invest;
        this.mainSourceFunds = data?.mainSourceFunds;
        this.businessManagement = data?.businessManagement;
        this.knowledge_quiebra = this.knowledge_quiebra;
        this.studyLvl = data.studyLvl;
        this.profession = data?.profession;
        this.sup500k = data?.sup500k;
      }
      this.stage1Progress = this.isCompany ? 25 : 20;
    });
    this.subscription = this.kycSrv.currentStepData.subscribe(data => {
      if (data) {
        console.log(data)
        this.currentStage = data.nextStage <= 0 ? 1 : data.nextStage;
        this.currentStep = data.nextStep <= 0 ? 1 : data.nextStep;
        this.stage1Progress = data.stage1Progress || 0;
        this.stage2Progress = data.stage2Progress || 0;
        this.stage3Progress = data.stage3Progress || 0;
        if( (this.isPerson && this.currentStep == 16 && this.currentStage == 3) || (this.isCompany && this.currentStep == 21 && this.currentStage == 3) ){
          this.kycSrv.setModalContent('stages');
        }
      }
    });
  }
  async neoToken() {
    this.fns.httpsCallable('neoToken')({}).subscribe(async (res:any) =>{
      if (!res.success) { return; }
      this.token = res.token;
      let video = String(await this.getToken())
      this.openlink =  this.sanitizer.bypassSecurityTrustResourceUrl(video);
    });
  }

  async getToken() {
    return new Promise(async(resolve,reject)=> {
        this.configuration = {headers: { Authorization: `Bearer ${this.token}`}};
        await axios.post(this.url_neo_video,{"language":"es","companyname":"Domoblock",},
        this.configuration).then((response) => {
           this.openlink = response.data;
           this.openlink2 = response.data;
          return (this.openlink);
        });
      resolve(this.openlink)
    });
  }
  
  async saveUserData() {
    this.loadingMessage = `Verificando ${this.isPerson ? 'KYC' : 'KYB'}...`;
    this.isLoading = true;
    const text = String(this.openlink2)
    const text1 = text.replace('https://safeonboarding.net/VideoIdentification/Index/','');
    const neoId = text1.replace('?language=es','');
    this.fns.httpsCallable('neocheckKYC')(neoId).subscribe(async (res:any) => {
      if (!res.success) { 
        Swal.fire({titleText:'Ha habido algun problema con la validacion',
        text:'Revisa si has pasado correctamente la video verificacion',  
        confirmButtonText:'OK'
      }).then((result) => {
        console.log('error',res)
      });
      this.isLoading = false;
      this.currentStep = this.isPerson ? 14 : 19;
      return; 
    } else {
      const result = (await this.modalConnectionService.getRegisterUserData()).data() as any;
      const userUid = this.modalConnectionService.getUserUid();
      let canProceed = true;
      if(this.isPerson){
        // bloque datos de usuario
          const particular = {
            nombre:result.userdata?.nombre,
            apellido1:result.userdata?.apellido || '',
            apellido2:"",
            domicilio:result.userdata?.domicilio || '',
            telefono:result.userdata?.telefono || '',
            ciudad:result.userdata?.ciudad || '',
            codigo_postal:result.userdata?.codigo_postal || '',
            nacionalidad:result.userdata.nacionalidad || '',
            fecha_de_nacimiento:result.userdata.fecha_de_nacimiento || '',
            pais_de_residencia:result.userdata.pais_de_residencia || '',
            vive_en_usa:result.userdata.vive_en_usa || '',
            trabaja_funcion_publica:result.userdata.trabaja_funcion_publica || '',
            funcion_publica:"Sin especificar",
          }
          const fininfo = {
           // bloque datos financieros
            target_invest_patri:result.userdata.fundsPercentage || '',
            // ingreso_anual:result.userdata.mainSourceFunds || '',
            ingreso_anual:result.userdata.sourceFunds || '',
            gestion_de_empresas_sector:result.userdata.businessManagement || '',
            knoledgement_securtoken:result.userdata.knowledge_quiebra || 'No',
            knoledgement_quiebra:result.userdata.knowledge_quiebra || 'No',
            estudios:result.userdata.studyLvl || '',
            profesion:result.userdata.profession || '',
            negative_support:result.userdata.negative_support || '',
            fuente_ingresos:result.userdata.mainSourceFunds || '',
            // fuente_ingresos:result.userdata.sourceFunds || '',
            origen_fondos:result.userdata.sourceFundsOrigin || '',
            inversor_pro:result.userdata.isProfessionalInvestor || '',
            lastoper:result.userdata.lastoper || false,
            sup500k:result.userdata.sup500k || false,
            oper_job:result.userdata.oper_job || false,
            target_invest:result.userdata.target_invest || ''
          }
    
          const disclaimer = {
           datos_veraces:true,
           politica_de_privacidad:true,
           terminos_y_condiciones_de_uso:true,
          }
          if(
            result.userdata.trabaja_funcion_publica == 'Si' ||
            result.userdata.knoledgement_quiebra == 'No' ||
            !(result.userdata.pais_de_residencia in this.countriesApproved)
          ){
            canProceed = false
          }
          // graba datos del usuario
          await this.afs.collection('users').doc(userUid)
          .set({
            particular,
            disclaimer,
            fininfo,
          },{merge: true });
      }
      else{
        if(this.isCompany){
          canProceed = false;
          const representante = {
            nombre:result.userdata.companyRepresentName || '',
            apellido1:result.userdata.companyRepresentLastname || '',
            apellido2:'',
            domicilio:result.userdata.companyRepresentAddress || '',
            telefono:result.userdata.companyPhoneNumber || '',
            ciudad:result.userdata.companyRepresentCity || '',
            codigo_postal:result.userdata.companyRepresentMailAddress || '',
            documentIdentification:'',
            nacionalidad:result.userdata.companyRepresentNationality || '',
            fecha_de_nacimiento:result.userdata.companyRepresentBirthday || '',
            pais_de_residencia:result.userdata.companyRepresentResidence || '',
            vive_en_usa:result.userdata.companyRepresentantisUsaCitizen || '',
            trabaja_funcion_publica:result.userdata.companyRepresentantisPoliticallyExposed || '',
            funcion_publica:'',
            empresa_titulo:result.userdata.companyRepresentTitle || '',
          };

          const empresa = {
            Empresa:'Si',
            CIF:result.userdata.cif || '',
            Razon_Social:result.userdata.companyName || '',
            Forma_juridica:result?.userdata?.companyType || '',
            Nombre_de_la_empresa:result.userdata.companyName || '',
            Acta_de_la_empresa:result.userdata.fileacta || 'problema con la acta',
            Escritura_de_la_sociedad:result.userdata.fileescrito || 'problemas con la escritura',
            otrosdoc:result.userdata.fileotros || 'problemas con otros documentos'
          }
    
          const  finentrepriseinfo = {
            inversor_pro:result.userdata?.companyIsProfessionalInvestor || false,
            cliente_profesional:result.userdata?.profesionalClient || false,
            Activos_mas_de_20M:result.userdata?.lastoper || false,
            Negocios_sociedad_mas_40M:result.userdata?.sup40m || false,
            Recursos_sociedad_sup_2M:result.userdata?.oper_job || false,
          }
          const disclaimer = {
            datos_veraces:true,
            politica_de_privacidad:true,
            terminos_y_condiciones_de_uso:true,
          }
          const titulares: any[] = [];
          this.representants.forEach((v,k)=>{
            titulares.push({
              nombre:v.companyRepresentName || '',
              apellido1:v.companyRepresentLastname || '',
              apellido2:'',
              domicilio:v.companyRepresentAddress || '',
              telefono:v.companyPhoneNumber || '',
              ciudad:v.companyRepresentCity || '',
              codigo_postal:v.companyRepresentMailAddress || '',
              documentIdentification:'',
              nacionalidad:v.companyRepresentNationality || '',
              fecha_de_nacimiento:v.companyRepresentBirthday || '',
              pais_de_residencia:v.companyRepresentResidence || '',
              vive_en_usa:v.companyRepresentantisUsaCitizen || '',
              trabaja_funcion_publica:'',
              funcion_publica:'',
            })
          })
          await this.afs.collection('users').doc(userUid)
          .set({
            'Empresa':{
              'Datos_Empresa':empresa,
              'Titulares':titulares,
              'Representante':representante,
              'Inversor_Pro':finentrepriseinfo,
              
            },disclaimer
            },{merge: true});
        }
      }
      this.kycSrv.isInNeocheckStep(false)
      setTimeout(()=>{
        this.isLoading = false;
        if(this.duplicatedKYC){
          this.kycSrv.setModalContent('completed');
          return;
        }
        this.backToKycStages(); 
      },12000);         
    }
    });
  }
  
  

  delayButton() {
    setTimeout(() => {
      this.disablebuton = false
    }, 100000);
  }

  backStep() {
    this.decrementCurrentStep();
    this.decrementCurrentStagePercentage();
  }

  nextStep() {
    let data = {} as any
    switch(this.getCurrentStep()) {
      case 1:{
        if(this.isPerson){
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Asegúrate de que la fecha de hoy no tenga información de tiempo
  
          if (this.userName === '' || this.userLastname === '' || 
              !this.userBirthdate || this.userBirthdate > today) {
            this.mandatoryFields();
          return;
          }
  
          const userBirthdate = new Date(this.userBirthdate);
  
          let age = today.getFullYear() - userBirthdate.getFullYear();
          const m = today.getMonth() - userBirthdate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < userBirthdate.getDate())) {
            age--;
          }
  
          if (age < 18 && this.isPerson) {
            Swal.fire({
              icon: 'error',
              title: 'Eres menor de edad',
              text: '¡Recuerda que para poder invertir con Domoblock debes tener mas de 18 años!',
            });
            return;
          }
          
          data = {
            nombre:this.userName,
            apellido:this.userLastname,
            fecha_de_nacimiento:this.userBirthdate
          }
        }else{
          
          if(!this.companyName || !this.cif || !this.companyType){
            this.mandatoryFields();
            return;
          }
          data = {
            companyName:this.companyName,
            cif:this.cif,
            companyType:this.companyType
          }
        }
        break;
      }
      case 2:{
        if(this.isPerson){
          if (!this.nationality || !this.residence) {
            this.mandatoryFields();
            return;
          }
          data = {
            nacionalidad:this.nationality,
            pais_de_residencia:this.residence,
          }
        }
        else{
          if(!this.companyAddress || !this.companyResidence || !this.companyCity || !this.companyMailAddress){
            this.mandatoryFields();
            return;
          }
          data = {
            companyAddress: this.companyAddress,
            companyResidence: this.companyResidence,
            companyCity: this.companyCity,
            companyMailAddress: this.companyMailAddress
          }
        }
        break;
      }
      case 3:{
        if(this.isPerson){
          if (this.address === '' || this.city === '' || this.mailAddress === '') {
            this.mandatoryFields();
            return;
          }
          data = {
            domicilio:this.address,
            ciudad:this.city,
            codigo_postal:this.mailAddress
          }
        }else{
          if(!this.fileacta || !this.filescrito){
            this.mandatoryFields();
            return;
          }
        }
        break;
      }
      case 4:{
        if(this.isPerson){
          if (this.phonePrefix === '' || this.phoneNumber === '') {
            this.mandatoryFields();
          return;
          }
          data = {
            telefono: `${this.phonePrefix}${this.phoneNumber}`
          }
        }else{
          if(!this.companyIsProfessionalInvestor){
            this.mandatoryFields();
            return;
          }
          data = {
            companyIsProfessionalInvestor:this.companyIsProfessionalInvestor
          }
          if (this.companyIsProfessionalInvestor === 'No') this.incrementCurrentStep();
        }
        break;
      }
      case 5:{
        if(this.isPerson){
          if (!this.isUsaCitizen || !this.isPoliticallyExposed) {
            this.mandatoryFields();
            return;
          }
          this.incrementCurrentStep();
          data = {
            vive_en_usa:this.isUsaCitizen,
            trabaja_funcion_publica:this.isPoliticallyExposed
        }
      }
      else{
        let fieldsSelected = 0;
        fieldsSelected += this.lastoper ? 1 : 0;
        fieldsSelected += this.sup40m ? 1 : 0;
        fieldsSelected += this.oper_job ? 1 : 0;
        if (fieldsSelected < 2 || !this.requiredField) {
          this.mandatoryFields();
          return;
        }
        data = {
          profesionalClient: this.requiredField,
          lastoper:this.lastoper || '',
          sup40m:this.sup40m || '',
          oper_job:this.oper_job || ''
        }        
      }
        break;
      }
      case 6: {
        if(this.isPerson) this.incrementCurrentStep();
        if(this.isCompany){
          if(!this.companyRepresentTitle){
            this.mandatoryFields();
            return;
          }
          data = {
            companyRepresentTitle:this.companyRepresentTitle
          }
        }
        break;
      }
      case 7:{
        if(this.isPerson){
          if (!this.profession || !this.studyLvl || !this.mainSourceFunds) {
            this.mandatoryFields();
          return;
          }
          data = {
            profession: this.profession,
            studyLvl: this.studyLvl,
            mainSourceFunds:this.mainSourceFunds
          }
        }
        if(this.isCompany){

          if(!this.companyRepresentName || !this.companyRepresentLastname || !this.companyRepresentBirthday){
              this.mandatoryFields();
              return;
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const userBirthdate = new Date(this.companyRepresentBirthday);
  
            let age = today.getFullYear() - userBirthdate.getFullYear();
            const m = today.getMonth() - userBirthdate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < userBirthdate.getDate())) {
              age--;
            }
    
            if (age < 18) {
              Swal.fire({
                icon: 'error',
                title: 'Eres menor de edad',
                text: '¡Recuerda que para poder invertir con Domoblock debes tener mas de 18 años!',
              });
              return;
            }

          data = {
            companyRepresentName: this.companyRepresentName,
            companyRepresentLastname: this.companyRepresentLastname,
            companyRepresentBirthday: this.companyRepresentBirthday
          }

        }
        break;
      }
      case 8:{
        if(this.isPerson){
          if (!this.sourceFunds || !this.sourceFundsOrigin || !this.fundsPercentage) {
            this.mandatoryFields();
            return;
          }
          data = {
            sourceFunds:this.sourceFunds,
            sourceFundsOrigin:this.sourceFundsOrigin,
            fundsPercentage: this.fundsPercentage
          }
        }
        if(this.isCompany){
            if(!this.companyRepresentNationality || !this.companyRepresentResidence){
            this.mandatoryFields();
            return;
          }

          data = {
            companyRepresentNationality: this.companyRepresentNationality,
            companyRepresentResidence: this.companyRepresentResidence
          }
          }
        break;
      }
      case 9:{
        if(this.isPerson){
          if (!this.negative_support || !this.target_invest || !this.invest_goal) {
            this.mandatoryFields();
          return;
          }
          data = {
            negative_support:this.negative_support,
            target_invest:this.target_invest,
            invest_goal:this.invest_goal
          }
        }
        else{
          if(!this.companyRepresentAddress || !this.companyRepresentCity || !this.companyRepresentMailAddress || !this.companyRepresentResidence){
              this.mandatoryFields();
              return;
          }

          data = {
            companyRepresentAddress:this.companyRepresentAddress,
            companyRepresentCity:this.companyRepresentCity,
            companyRepresentMailAddress:this.companyRepresentMailAddress
          }
        }
        break;
      }
      case 10:{
        if(this.isPerson){
          if (!this.businessManagement || !this.knowledge_quiebra) {
            this.mandatoryFields();
           return;
          }
  
         data = {
           businessManagement: this.businessManagement,
           knowledge_quiebra:this.knowledge_quiebra,
         } 
        }else{
           if(!this.phonePrefix || !this.companyPhoneNumber){
            this.mandatoryFields();
            return;
          }

          data = {
            companyPhoneNumber: `${this.phonePrefix}${this.companyPhoneNumber}`
          }
      }
        break;
      }
      case 11:{
        if(this.isPerson){
          if (!this.isProfessionalInvestor) {
            this.mandatoryFields();
            return;
          }
  
          if ( this.isProfessionalInvestor === 'No' ) this.incrementCurrentStep();
          data = {
            isProfessionalInvestor:this.isProfessionalInvestor,
          } 
        }else{

          if(!this.companyRepresentantisUsaCitizen || 
            !this.companyRepresentantisPoliticallyExposed){
              this.mandatoryFields();
              return;
            }

            data = {
              companyRepresentantisUsaCitizen:this.companyRepresentantisUsaCitizen,
              companyRepresentantisPoliticallyExposed:this.companyRepresentantisPoliticallyExposed
            }
        }
        break;
      }
      case 12:{
        if(this.isPerson){
          let fieldsSelected = 0;
          fieldsSelected += this.lastoper ? 1 : 0;
          fieldsSelected += this.sup500k ? 1 : 0;
          fieldsSelected += this.oper_job ? 1 : 0;
          if (fieldsSelected < 2 || !this.requiredField) {
            this.mandatoryFields();
            return;
          }
          data = {
            lastoper:this.lastoper || '',
            sup500k:this.sup500k || '',
            oper_job:this.oper_job  || ''
          }
        }else{
          if (!this.isRealRepresentant) {
            this.mandatoryFields();
            return;
          }
          if (this.isRealRepresentant === 'Si') {
            this.currentStep = 18;
          }
          data = {
            isRealRepresentant:this.isRealRepresentant
           }
        }
        break;
      }
      case 13:{
        if (this.isPerson) {
          this.incrementCurrentStep();
        }else{
          if(this.realRepresentantsQuantity < 1 || this.realRepresentantsQuantity > 5){
            Swal.fire({
              title:'Número de titulares inválido',
              text:'Por favor, selecciona un número de titulares válido. Entre 1 y 5',
              icon: 'warning'
            })
            return;
          }
          this.clearFields();
        }
        break;
      }
      case 14:{
          if (this.isPerson) {
          this.disablebuton = true;
          this.delayButton();
          }

          if (this.isCompany) {
            if(!this.companyRepresentName || 
            !this.companyRepresentLastname || 
            !this.companyRepresentBirthday){
              this.mandatoryFields();
              return;
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const userBirthdate = new Date(this.companyRepresentBirthday);
  
            let age = today.getFullYear() - userBirthdate.getFullYear();
            const m = today.getMonth() - userBirthdate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < userBirthdate.getDate())) {
              age--;
            }
    
            if (age < 18) {
              Swal.fire({
                icon: 'error',
                title: 'Este titular es menor de edad',
                text: '¡Recuerda que para poder invertir con Domoblock debe tener mas de 18 años!',
              });
              return;
            }

            this.representants.set(this.index,{
              ...this.representants.get(this.index),
              companyRepresentName:this.companyRepresentName,
              companyRepresentLastname:this.companyRepresentLastname,
              companyRepresentBirthday:this.companyRepresentBirthday
            })

          }
        break;
      }
      case 15:{
        if (this.isCompany) {
          if(!this.companyRepresentNationality || !this.companyRepresentResidence){
          this.mandatoryFields();
          return;
          }
          this.representants.set(this.index,{
              ...this.representants.get(this.index),
              companyRepresentNationality:this.companyRepresentNationality,
              companyRepresentResidence:this.companyRepresentResidence          
            })

        }
        break;
      }
      case 16:{
          if(!this.companyRepresentAddress || !this.companyRepresentCity || !this.companyRepresentMailAddress || !this.companyRepresentResidence){
          this.mandatoryFields();
            return;
          }
          this.representants.set(this.index,{
            ...this.representants.get(this.index),
            companyRepresentAddress:this.companyRepresentAddress,
            companyRepresentCity:this.companyRepresentCity,
            companyRepresentMailAddress:this.companyRepresentMailAddress
          })

        break;
      }
      case 17:{
        if (this.isCompany) {
               if(!this.phonePrefix || !this.companyPhoneNumber){
            this.mandatoryFields();
            return;
          }
          this.representants.set(this.index,{
            ...this.representants.get(this.index),
            companyPhoneNumber: `${this.phonePrefix}${this.companyPhoneNumber}`
        })

        }
        break;
      }
      case 18:{
        if (this.isCompany) {
          if(!this.companyRepresentantisUsaCitizen || 
            !this.companyRepresentantisPoliticallyExposed){
              this.mandatoryFields();
              return;
            }
            this.representants.set(this.index,{
              ...this.representants.get(this.index),
              companyRepresentantisUsaCitizen:this.companyRepresentantisUsaCitizen,
              companyRepresentantisPoliticallyExposed:this.companyRepresentantisPoliticallyExposed
            })
            if(this.index < this.realRepresentantsQuantity){
              this.clearFields();
              this.currentStep = 13;
              this.index++;
            }
        }
        break;
      }

      case 19:{
        if(this.isCompany){    
          const representants = Array.from(this.representants.values())
          data = {representants}
          this.disablebuton = true;
          this.delayButton();
        }
        break;
      }

      default:{
        //TODO
        break;
      }
    }
    this.incrementCurrentStep()
    this.incrementCurrentStagePercentage();
    /*
    if (this.stage3Progress > 100) {
      if (this.isPerson) return;
    } */

    if (this.isPerson) {
      console.log(this.currentStep)
    switch (this.currentStep) {
      case 7:
        this.setCurrentStage(2);
        this.incrementCurrentStagePercentage();
        break;
      case 13:
        this.setCurrentStage(3);
        this.incrementCurrentStagePercentage();
        this.incrementCurrentStep();
        this.kycSrv.isInNeocheckStep(true);
        break;
      case 16:
        this.saveUserData();
        break;
      default:
        break;
    }
  }

  if (this.isCompany) {
    switch (this.currentStep) {
      case 6:
        this.setCurrentStage(2)
        this.incrementCurrentStagePercentage();
        break;
      case 19:
        this.setCurrentStage(3)
        this.incrementCurrentStagePercentage();
        this.kycSrv.isInNeocheckStep(true);
        break;
      case 21:
        this.saveUserData();
        break;
      default:
        break;
    }
  }

  if(Object.keys(data).length > 0) {
    this.modalConnectionService.saveCurrentData(data,this.getCurrentStep(),this.getCurrentStage(),{stage1Progress:this.stage1Progress, stage2Progress:this.stage2Progress, stage3Progress:this.stage3Progress});
  }

  }

  async backToKycStages() {
    const userData = await this.modalConnectionService.getUserData();
    if(userData?.kyc?.result == 2){
      this.kycSrv.setModalContent('completed');
      return;
    }
    //this.kycSrv.setStageData({nextStage: 3, nextStep: 1});
    this.kycSrv.isInNeocheckStep(false);
    //this.kycSrv.setModalContent('stages');
    this.kycSrv.setModalContent('completed');
    this.modalConnectionService.saveCurrentData({aux:true},0,3,{stage1Progress:this.stage1Progress, stage2Progress:this.stage2Progress, stage3Progress:this.stage3Progress});
  }

  getCurrentStep(){
    return this.currentStep;
  }

  getCurrentStage(){
    return this.currentStage;
  }

  incrementCurrentStep(){
    this.currentStep += 1;
  }

  decrementCurrentStep(){
    if (this.currentStage == 1 && this.currentStep == 1) {
      this.kycSrv.setStageData({nextStage: 1, nextStep: 1, backFromRegister: true, isPerson: this.isPerson, isCompany: this.isCompany});
      this.kycSrv.setModalContent('stages');
      this.modalConnectionService.saveCurrentData({aux:true},0,1);
    }
    if(this.currentStep == 14 && this.isPerson){
      this.currentStep--;
      this.currentStage--;
      if(this.isProfessionalInvestor =='No')
        this.currentStep--;
    }
    if(this.currentStep == 19 && this.currentStage == 3)
        this.currentStage--;
    if (this.currentStep == 6){
      if(this.companyIsProfessionalInvestor === 'No')
        this.currentStep -=1;
      this.currentStage -= this.currentStage > 1 ? 1 : 0;
      this.stage2Progress = 0;
      this.stage1Progress = 100;
    } 
    this.currentStep -= 1;
  }

  setCurrentStage(stage:number){
    this.currentStage = stage;
  }

  incrementCurrentStagePercentage(){
    switch (this.currentStage) {
      case 1:{
        if(this.isPerson) this.stage1Progress += 20;
        if(this.isCompany) this.stage1Progress += 25;
        break;
      }
      case 2:{
        if(this.isPerson) this.stage2Progress += 20;
        if(this.isCompany) this.stage2Progress += 14.29;
        break;
      }
      case 3:{
        this.stage3Progress += 50;
        break;
      }
    }
    // console.log(this.stage2Progress)
    // if(this.stage2Progress > 120)
    //   this.stage3Progress += 50;
  }

  decrementCurrentStagePercentage(){
    switch (this.currentStage) {
      case 1:{
        this.stage1Progress -= 20;
        break;
      }
      case 2:{
        this.stage2Progress -= 20;
        break;
      }
    }
    if(this.stage2Progress > 120)
      this.stage3Progress -= 50;
  }

  mandatoryFields(fb = ()=>{}){
      return (
        Swal.fire({
          title:'Campos obligatorios',
          text:'Por favor rellene todos los campos obligatorios para continuar',
          icon: 'warning'
        })
      ).then(fb)
  }

  async close(){
    // if (this.currentStage >= 2) {
    //   this.dialogRef.close();
    //   return;
    // } 
    await this.authSrv.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/login');
    this.authSrv.reload();
  }

onFileChange(event: any) {
  for (let file of event.target.files) {
    this.files.push(file);
  }
}

removeFile(index: number) {
  this.files.splice(index, 1);
}

clearFields(){
  this.companyRepresentName = null;
  this.companyRepresentLastname = null;
  this.companyRepresentBirthday = null;
  this.companyRepresentMailAddress = null;
  this.companyRepresentResidence = null;
  this.companyRepresentCity = null;
  this.companyRepresentNationality = null;
  this.companyRepresentAddress = null;
  this.phonePrefix = '';
  this.companyPhoneNumber = null;
  this.companyRepresentantisPoliticallyExposed = null;
  this.companyRepresentantisUsaCitizen = null;
}

async uploadActa(e: any) {
  this.loadingMessage = 'Subiendo archivo del acta...';
  this.isLoading = true;
  const uid = this.modalConnectionService.getUserUid();
  const file = e.target.files[0];
  if(!file){
    this.mandatoryFields();
    return;
  }
  if(file.size > 20971520){
    this.isLoading = false;
    Swal.fire({
      title:'Limite de peso excedido',
      text:'Ha sobrepasado el tamaño limite permitido del archivo, intente con un archivo mas ligero',
      icon:'warning'
    });
    return;
  }
  const filePath = `enterprisedocs/${uid}/${file.name}`;
  const fileRef = this.storageService.ref(filePath);
  const task = this.storageService.upload(filePath, file);
  task.snapshotChanges().pipe(
    finalize(() => {
      fileRef.getDownloadURL().subscribe(async(res: string) => {
        this.acta = res;
        this.fileacta = file.name
        if (this.acta !== null && this.acta !== undefined) {
          this.files.push({label: 'Acta', file: file})
        }
        if (this.acta === null || this.acta === undefined) {
          Swal.fire({
            title: 'Error al subir el archivo',
            text: 'Por favor, vuelve a intentarlo',
            icon: 'error'
          });
        }
        await this.modalConnectionService.saveCurrentData({fileacta:this.fileacta},this.currentStep,this.currentStage);
        this.isLoading = false;
      })
    })).subscribe();
}

async uploadEscritura(e: any) {
  this.isLoading = true;
  this.loadingMessage = 'Subiendo archivo de escritura...';
  const uid = this.modalConnectionService.getUserUid();
  const file = e.target.files[0];
  if(!file){
    this.mandatoryFields()
    return;
  }
  const filePath = `enterprisedocs/${uid}/${file.name}`;
  const fileRef = this.storageService.ref(filePath);
  const task = this.storageService.upload(filePath, file);
  task.snapshotChanges().pipe(
    finalize(() => {
      
      fileRef.getDownloadURL().subscribe(async (res: string) => {
        this.escrituras = res;
        this.filescrito = file.name
        if (this.escrituras !== null && this.escrituras !== undefined) {
          this.files.push({label: 'Escritura', file: file})
        }
        if (this.escrituras === null || this.escrituras === undefined) {
          Swal.fire({
            title: 'Error al subir el archivo',
            text: 'Por favor, vuelve a intentarlo',
            icon: 'error'
          });
        }
        console.log(this.escrituras,this.escrituras)
        await this.modalConnectionService.saveCurrentData({fileescrito:this.filescrito},this.currentStep,this.currentStage);
        this.isLoading = false;
      });
    })).subscribe();
}

get uploadedEscrituraFile(): boolean {
  const res = this.files.some((file: any) => file.label === 'Escritura');
  return !res
}

get uploadedActaFile(): boolean {
  const res = this.files.some((file: any) => file.label === 'Acta');
  return !res
}

get uploadedOtrosFile(): boolean {
  const res = this.files.some((file: any) => file.label === 'Otros');
  return !res
}

async uploadDocs(e: any) {
  this.loadingMessage = 'Subiendo archivo de otros documentos...';
  this.isLoading = true;
  const uid = this.modalConnectionService.getUserUid();
  const file = e.target.files[0];
  if(!file){
    this.mandatoryFields();
    return;
  }
  const filePath = `enterprisedocs/${uid}/${file.name}`;
  const fileRef = this.storageService.ref(filePath);
  const task = this.storageService.upload(filePath, file);
  task.snapshotChanges().pipe(
    finalize(() => {
      fileRef.getDownloadURL().subscribe(async (res: string) => {
        this.otrosdoc = res;
        this.fileotros = file.name
        if (this.fileotros !== null && this.fileotros !== undefined) {
          this.files.push({label: 'Otros', file: file})
        }
        if (this.fileotros === null || this.fileotros === undefined) {
          Swal.fire({
            title: 'Error al subir el archivo',
            text: 'Por favor, vuelve a intentarlo',
            icon: 'error'
          });
        }
        await this.modalConnectionService.saveCurrentData({fileotros:this.fileotros},this.currentStep,this.currentStage);
        this.isLoading = false;
      });
      console.log(this.otrosdoc,this.fileotros)
    })).subscribe();
}

clickKnowledge_quiebra(){
  Swal.fire({
    title: 'Necesitamos confirmar que conoces lo que es un préstamo participativo y los riesgos que la inversión en los mismos conlleva.',
    html: `
      <p">El préstamo participativo es un instrumento financiero que se sitúa entre el capital social y el préstamo tradicional. Su principal particularidad radica en que su retribución está directamente ligada al desempeño de la sociedad prestataria. En este contexto, Domoblock ha digitalizado este concepto al asociarlo a la plusvalía generada por la remodelación o reforma de un inmueble. De esta manera, el inversor recibirá un interés que se ajustará según el progreso del proyecto inmobiliario.</p>
      <div">
        <input type="checkbox" id="confirmCheckbox">
        <label for="confirmCheckbox">Entiendo lo que es un préstamo participativo y los riesgos que conlleva</label>
      </div>
    `,
    icon: 'warning',
    width: '98%',
    customClass: {
      popup: 'custom-swal-width' // Añade una clase personalizada al popup
    },
    preConfirm: () => {
      const checkbox = document.getElementById('confirmCheckbox') as HTMLInputElement;
      if (!checkbox.checked) {
        Swal.showValidationMessage('Debes confirmar que entiendes los riesgos');
        return;
      }
      this.knowledge_quiebra = 'Si';
    }
  });
}

}

