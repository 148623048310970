import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { KycService } from 'src/app/services/kyc.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { KycService as ModalKycSrv } from 'src/app/services/modal-kyc/kyc.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
// import { error } from 'jquery';
import { LoaderService } from 'src/app/services/loader.service';
import { DigitalWalletService } from 'src/app/services/digital-wallet.service';

@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.css']
})
export class MenuDesktopComponent implements OnInit {
  userAuth: any = null;
  loggedIn: boolean = false;
  kycFlag: boolean = false;
  adminFlag: boolean = false; 
  urlYoutube: string | null = null;
  urlLinkedIn: string | null = null;
  urlTwitter: string | null = null;
  urlInstagram: string | null = null;
  urlTelegram: string | null = null;
  digitalWalletCollapsed: boolean = false;
  helpCollapsed: boolean = false;
  balance: number = 0;
  balanceError: boolean = false;
  isLoadingBalance: boolean = false;
  loadingDots: string = '';
  hideBlockchain: boolean = true;
  constructor(
    private authSrv: AuthService, 
    private kycSrv: KycService, 
    private afs: AngularFirestore, 
    private crf: ChangeDetectorRef,
    private router: Router,
    private modalKyc: ModalKycSrv,
    public fns: AngularFireFunctions,
    private loadingService: LoaderService,
    private digitalWalletSrv: DigitalWalletService
  ) {}

  logout() {
    Swal.fire({
      icon: 'info',
      title: '¿Quieres salir de la aplicación?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Cancelar',
      cancelButtonText: `Ok`,
    }).then(async (result: any) => {
      if (!result.isConfirmed) {
        await this.authSrv.logout();
        this.router.navigateByUrl('/login');
        this.authSrv.reload();
      }
    });
  }

  formatVideo() {
    setTimeout(() => {
      const iytb: HTMLElement = document.getElementById('iframeYoutube') as HTMLElement;
      if (iytb === null) {
        this.formatVideo();
        return;
      }

      const width = iytb.offsetWidth;
      const height = iytb.offsetHeight;
      const proporcion = (height / width) + 0.3;
      iytb.setAttribute('height', String(width * proporcion));
    }, 1000);
  }

  ngOnInit(): void {
    this.authSrv.userAuth.subscribe((res: any) => {
      this.userAuth = res;
      this.afs.collection('users').doc(res?.uid).valueChanges().subscribe((user: any) => { 
          this.hideBlockchain = user?.hideBlockchain ?? true;
          })
      this.crf.detectChanges();
    });

    this.authSrv.loggedIn.subscribe((res: any) => {
      const previousLoggedIn = this.loggedIn;
      this.loggedIn = res != this.loggedIn ? res : this.loggedIn;
      if (this.loggedIn && this.loggedIn !== previousLoggedIn) {
        this.checkFunds();
      }
      this.crf.detectChanges();
    });

    this.authSrv.admin.subscribe((res: any) => {
      this.adminFlag = res;
      this.crf.detectChanges();
    });

    this.kycSrv.kycSuccess.subscribe((res: any) => {
      this.kycFlag = res;
      this.crf.detectChanges();
    });


    this.digitalWalletSrv.currentBalance.subscribe((res: any) => {
      if (res) {
        this.balance = res.balance;
        this.balanceError = res.balanceError;
        this.isLoadingBalance = res.isLoadingBalance;
        this.crf.detectChanges();
      }
    });

    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').valueChanges().subscribe((res: any) => {
      this.urlYoutube = res.urlYoutubeMenu;
      this.urlLinkedIn = res.urlLinkedInMenu;
      this.urlTwitter = res.urlTwitterMenu;
      this.urlInstagram = res.urlInstagramMenu;
      this.urlTelegram = res.urlTelegramMenu;
      this.crf.detectChanges();
    });

    this.formatVideo();
  }

  openModalKyc() {
    this.modalKyc.openModalKyc();
  }

  checkFunds() {
    this.digitalWalletSrv.checkFunds();
  }

}
