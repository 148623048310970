import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardsRealEstateComponent } from './components/cards-real-estate/cards-real-estate.component';
import { GuardGuard } from './guards/guard.guard';
import { AuthGuard } from './guards/auth.guard';
import { RegisterPageComponent } from './components/register-page/register-page.component';
import { ModalPageComponent } from './components/modal-page/modal-page.component';
import { AdminComponent } from './components/admin/admin.component';

const routes: Routes = [
  { path: 'administracion', component: AdminComponent, canActivate: [GuardGuard] }, //por convertir a modulo
  { path: 'oportunidades', component: CardsRealEstateComponent, canActivate: [GuardGuard]},
  { path: 'oportunidad', loadComponent: () => import('./components/invest-real-estate/invest-real-estate.component').then((m) => m.InvestRealEstateComponent), canActivate: [GuardGuard] }, 
  // { path: 'documentacion', component: DocumentationComponent },
  //{ path: 'mis-inversiones', component: UserInvestmentsComponent, canActivate: [GuardGuard] },
  // { path: 'mis-inversiones', component: MyInvestmentsComponent, canActivate: [GuardGuard] }, //por convertir a modulo
  { path: 'inversiones', loadChildren: () => import('./components/my-investments/my-investments.module').then((m) => m.MyInvestmentsModule), canActivate: [GuardGuard] },
  { path: 'pago-exitoso', loadComponent: () => import('./components/payment-result/payment-successful/payment-successful.component').then((m) => m.PaymentSuccessfulComponent), canActivate: [GuardGuard] },
  { path: 'pago-error', loadComponent: () => import('./components/payment-result/payment-error/payment-error.component').then((m) => m.PaymentErrorComponent), canActivate: [GuardGuard] },
  { path: 'register', component: RegisterPageComponent, canActivate: [AuthGuard] }, 
  { path: 'login',  component: ModalPageComponent, canActivate: [AuthGuard]},
  //{ path: 'kyc', component: KycComponent, canActivate: [GuardGuard] },
  // { path: 'perfil', component: ProfileComponent, canActivate: [GuardGuard] },
  { path: 'perfil', loadChildren: () => import('./components/my-profile/my-profile.module').then((m) => m.MyProfileModule), canActivate: [GuardGuard] }, //por convertir a modulo
  { path: 'contacto', loadComponent: () => import('./components/contact-us/contact-us.component').then((m) => m.ContactUsComponent), canActivate: [GuardGuard] },
  {
    path: 'cartera-digital',
    loadChildren: () => import('./components/digital-wallet/digital-wallet.module').then(m => m.DigitalWalletModule)
  },
  { path: '', pathMatch: 'full', redirectTo: '/oportunidades' },
  { path: '**', pathMatch: 'full', redirectTo: '/oportunidades' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'ignore',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
