<nav class="navbar fixed-top">
    <div class="container-fluid">

        <div class="d-flex gap-1 py-2">
            <button class="navbar-toggler bg-white" style="z-index: 1000;"
            type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <div>
                <a [routerLink]="['/oportunidades']">
                    <img src="../../../assets/img/logo.png" alt="Domoblock logotipo" style="height: 2.3rem; width: auto; margin-left: 20px;">
                </a>
            </div>
        </div>

        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style="padding: 1rem;">
            <div class="navbar-movil">
                <div class="offcanvas-header d-flex flex-column gap-4" >
                        <div [routerLink]="['/oportunidades']" data-bs-dismiss="offcanvas" aria-label="Close">
                            <img src="../../../assets/img/logo.png" alt="Domoblock logotipo">
                        </div>
                        <div class="divider"></div>
                </div>
                <div class="offcanvas-body ">
                    <div class="mb-3" *ngIf="loggedIn">
                        <h5 style="margin: 0 !important;">Cartera digital</h5>
                        <p class="m-0 p-0 no-hover">SALDO DISPONIBLE</p>
                        <div class="d-flex my-2 justify-content-between align-items-center">
                            <h5 style="margin: 0 !important;" *ngIf="isLoadingBalance" class="loading-text">Consultando balance</h5>
            
                            <h3 style="margin: 0 !important;" *ngIf="!balanceError && !isLoadingBalance" class="balance-text">{{balance | customCurrency}}</h3>
                            <img src="../../../assets/img/plus-circle.svg" alt="add-funds" class="add-funds"
                            [routerLink]="['/cartera-digital/euros']" *ngIf="!balanceError && !isLoadingBalance">
            
                            <h5 style="margin: 0 !important;" *ngIf="balanceError && !isLoadingBalance">Error al consultar el balance</h5>
                            <i class="fa fa-refresh fa-2x balance-text" aria-hidden="true" style="cursor: pointer; color: #F09502;"
                             *ngIf="!isLoadingBalance" (click)="checkFunds()"></i>
                        </div>
                    </div>
                    <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn && adminFlag" [routerLink]="['/administracion']" routerLinkActive="activo"><i class="bi bi-gear"></i> Administración</p>
                    <p data-bs-dismiss="offcanvas" aria-label="Close" [routerLink]="['/oportunidades']" routerLinkActive="activo" (click)="menuOpened = false"><i class="fa fa-building-o" aria-hidden="true"></i> Oportunidades</p>
                    
                    
                    <!-- <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn" [routerLink]="['/mis-inversiones']" routerLinkActive="activo"><i class="bi bi-piggy-bank"></i> Mis inversiones</p> -->
                    

                    <!-- Mis inversiones -->
                    <p *ngIf="loggedIn" (click)="selectedMenu('myInvestmentsCollapsed')" [ngClass]="{'padding-p-collapse': myInvestmentsCollapsed}">
                        <i class="bi bi-piggy-bank"></i>
                            Mis inversiones
                        <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
                        [class.rotate]="myInvestmentsCollapsed"></i>
                    </p>

                    <div class="card-body sub-option" [ngbCollapse]="!myInvestmentsCollapsed" style="padding-left: 1.1vw;">
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/inversiones/resumen-financiero']"> <i class="bi bi-chevron-right"></i>Resumen financiero</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/inversiones/movimientos']"> <i class="bi bi-chevron-right"></i>Movimientos</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/inversiones/mis-inversiones']"> <i class="bi bi-chevron-right"></i>Mis inversiones</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/inversiones/documentos']"> <i class="bi bi-chevron-right"></i>Documentos</p>
                    </div>

                    <!-- cartera digital -->
                    <p *ngIf="loggedIn" (click)="selectedMenu('digitalWalletCollapsed')" [ngClass]="{'padding-p-collapse': digitalWalletCollapsed}">
                        <i class="bi bi-credit-card"></i>
                        Cartera digital
                        <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
                        [class.rotate]="digitalWalletCollapsed"></i>
                    </p>

                    <div class="card-body" [ngbCollapse]="!digitalWalletCollapsed" style="padding-left: 1.1vw;">
                        <p *ngIf="!hideBlockchain" [routerLink]="['/cartera-digital/blockchain']" class="padding-p-collapse" routerLinkActive="activo" data-bs-dismiss="offcanvas" aria-label="Close"> <i class="bi bi-box"></i> En blockchain</p>
                        <p  routerLinkActive="activo" (click)="selectedMenu('eurosCollapsed')" [ngClass]="{'padding-p-collapse': eurosCollapsed}" > 
                            <i class="bi bi-currency-euro"></i> En euros <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;"  [class.rotate]="eurosCollapsed"></i>
                        </p>
                        <div class="card-body sub-option" [ngbCollapse]="!eurosCollapsed" style="padding-left: 1.1vw;">
                            <p data-bs-dismiss="offcanvas" aria-label="Close"
                            routerLinkActive="activo" [routerLink]="['/cartera-digital/euros/ingresar-fondos']"> <i class="bi bi-chevron-right"></i>Ingresar dinero</p>
                            <p data-bs-dismiss="offcanvas" aria-label="Close" 
                            routerLinkActive="activo" [routerLink]="['/cartera-digital/euros/retiros']"> <i class="bi bi-chevron-right"></i>Retirar dinero</p>
                            <p data-bs-dismiss="offcanvas" aria-label="Close"
                            routerLinkActive="activo" [routerLink]="['/cartera-digital/euros/movimientos']"> <i class="bi bi-chevron-right"></i>Movimientos</p>
                        </div>
                    </div>
                    <!-- <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn && !kycFlag" (click)="openModalKyc()" routerLinkActive="activo"><i class="bi bi-person-bounding-box"></i>Continuar KYC</p> -->
                    
                    <!-- Ayuda -->
                    <p *ngIf="loggedIn" (click)="selectedMenu('helpCollapsed')" [ngClass]="{'padding-p-collapse': helpCollapsed}">
                        <i class="bi bi-question-lg"></i>
                        Ayuda
                        <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
                        [class.rotate]="helpCollapsed"></i>
                    </p>

                    <div class="card-body sub-option" [ngbCollapse]="!helpCollapsed" style="padding-left: 1.1vw;">
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo"> <i class="bi bi-chevron-right"></i>Tutorial de inversión</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo"> <i class="bi bi-chevron-right"></i>Guía de inversión</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/contacto']"> <i class="bi bi-chevron-right"></i>Contáctanos</p>
                    </div>
                    
                    <!-- <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn" [routerLink]="['/perfil']" 
                    routerLinkActive="activo"><i class="bi bi-gear"></i> Perfil</p> -->

                    <!-- Perfil -->
                    <p *ngIf="loggedIn" (click)="selectedMenu('profileCollapsed')" [ngClass]="{'padding-p-collapse': profileCollapsed}">
                        <i class="bi bi-gear"></i> 
                            Perfil
                        <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
                        [class.rotate]="profileCollapsed"></i>
                    </p>

                    <div class="card-body sub-option" [ngbCollapse]="!profileCollapsed" style="padding-left: 1.1vw;">
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/perfil/usuario']"> <i class="bi bi-chevron-right"></i>Usuario</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/perfil/cambiar-contrasena']"> <i class="bi bi-chevron-right"></i>Cambiar contraseña</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/perfil/metodo-de-inversion']"> <i class="bi bi-chevron-right"></i>Método de inversión</p>
                        <p data-bs-dismiss="offcanvas" aria-label="Close"
                        routerLinkActive="activo" [routerLink]="['/perfil/alertas']"> <i class="bi bi-chevron-right"></i>Configurar alertas</p>
                    </div>
                    <!-- <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn" (click)="logout()"><i class="bi bi-x-octagon-fill" style="color:brown;"></i> Cerrar sesión</p> -->
                </div>
                <button class="navbar-toggler closeMenuMov" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" (click)="menuOpened = false"
                 aria-controls="offcanvasNavbar" style="position: absolute; bottom: 1rem; width: 90%; left: 45%; transform: translateX(-50%);">
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</nav>