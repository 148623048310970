import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { PaginationComponent } from '../UI/pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { EurosPipe } from 'src/app/pipes/euros.pipe';

@NgModule({
  declarations: [
    CustomCurrencyPipe,
    PaginationComponent,
    EurosPipe // Asegúrate de declarar EurosPipe aquí
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    CustomCurrencyPipe,
    EurosPipe, // Asegúrate de exportar EurosPipe aquí si lo necesitas en otros módulos
  ]
})
export class SharedModule { }
